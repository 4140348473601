import { Row, Col, Modal, Form, Input } from 'antd';
import { BasicFormWrapper, Main } from '../styled';
import { PricingCard, Badge } from './Style';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { addFraisData, updateFraisData, viewFraisData, deleteFraisData } from '../../redux/frais/actionCreator';
import { ReunionFormStyleWrap } from '../absence_eleve/Style';
import { ExclamationCircleFilled } from '@ant-design/icons';

function Tranches() {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { tranches } = useSelector((state) => {
    return {
      tranches: state.FraisReducer.data,
    };
  });

  const showPriceModal = () => {
    setOpenPriceModal(true);
  };

  const handlePriceCancel = () => {
    setOpenPriceModal(false);
  };
  const showAddModal = () => {
    setOpenAddModal(true);
  };

  const showUpdateModal = (values) => {
    form.setFieldsValue(values);
    setOpenAddModal(true);
  };

  const handleAddCancel = () => {
    setOpenAddModal(false);
  };

  const addSuccess = () => {
    dispatch(viewFraisData());
    handleAddCancel();
  };

  const updateSuccess = () => {
    dispatch(viewFraisData());
    handlePriceCancel();
  };

  const handleDelete = (id) => {
    dispatch(deleteFraisData(id));
  };

  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous supprimer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(data);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    dispatch(viewFraisData());
  }, []);

  const handleSubmit = (values) => {
    dispatch(addFraisData(values, addSuccess));
  };

  const handleSubmitPrice = (values) => {
    dispatch(updateFraisData(values, updateSuccess));
  };

  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Ajouter unu frais'}
        open={openAddModal}
        onCancel={handleAddCancel}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <Form name="addReunion" onFinish={handleSubmit}>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Mois</span>
                <Form.Item name="mois" rules={[{ required: true, message: 'Mois du frais est obligatoire !' }]}>
                  <Input placeholder="Saisir le mois du frais" />
                </Form.Item>
              </div>
              <br />
              <div className="add-event-footer text-right">
                <Button htmlType="submit" className="btn-save" type="primary">
                  Enregistrer
                </Button>
              </div>
            </Form>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>

      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Modifier le montant des tranches'}
        open={openPriceModal}
        onCancel={handlePriceCancel}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <Form name="addReunion" onFinish={handleSubmitPrice}>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Montant</span>
                <Form.Item name="prix" rules={[{ required: true, message: 'Montant du frais est obligatoire !' }]}>
                  <Input placeholder="Saisir le montant du frais" type={'number'} min={0} />
                </Form.Item>
              </div>
              <br />
              <div className="add-event-footer text-right">
                <Button htmlType="submit" className="btn-save" type="primary">
                  Enregistrer
                </Button>
              </div>
            </Form>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Main>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '10px' }}>
          <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }} onClick={showPriceModal}>
            Modifier le montant
          </Button>
          <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }} onClick={showAddModal}>
            Ajouter un frais
          </Button>
        </div>
        <Row gutter={25}>
          {tranches &&
            tranches.map((item) => (
              <Col key={item.id} xxl={8} lg={8} sm={12} xs={24}>
                <PricingCard style={{ marginBottom: 30 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge className="pricing-badge" type="primary">
                      {item.mois}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}>
                      <UilTrash size={17} color={'black'} onClick={() => showConfirm(item.id)} />
                    </div>
                  </div>
                  <Heading className="price-amount" as="h3">
                    <sup className="currency"></sup>
                    {item.prix} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            ))}
        </Row>
      </Main>
    </>
  );
}

export default Tranches;
