import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Checkbox, Divider, Form, message, Input, Select } from 'antd';
import { Badge, PricingCard } from '../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/heading/heading';
import { viewFactureData } from '../../../redux/inscription/actionCreator';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import { addPaimentData } from '../../../redux/paiment/actionCreator';
import { BasicFormWrapper } from '../../styled';
import { ReunionFormStyleWrap } from '../../reunion/Style';

import {
  attachAutreFraisData,
  detachAutreFraisData,
  viewAutreFraisData,
  viewInscriptionAutreFraisData,
} from '../../../redux/autreFrais/actionCreator';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

function Tranches() {
  let { id } = useParams();
  const [paimentForm] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [autreTranche, setAutreTranche] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [isPaimentOpen, setIsPaimentOpen] = useState(false);
  const [selectedTranche, setSelectedTranche] = useState(null);

  const handlePaimentOpen = (data) => {
    if (!data.paid || data.paid < data.prix) {
      setSelectedTranche(data);
      setIsPaimentOpen(true);
    }
  };
  const handlePaimentClose = () => {
    paimentForm.resetFields();
    setSelectedTranche(null);
    setIsPaimentOpen(false);
  };

  const { attachedTranches, tranches, facture } = useSelector((state) => {
    return {
      facture: state.InscriptionReducer.facture,
      tranches: state.AutreFraisReducer.data,
      attachedTranches: state.AutreFraisReducer.attached,
    };
  });

  useEffect(() => {
    dispatch(viewAutreFraisData());
    dispatch(viewInscriptionAutreFraisData(id));
    dispatch(viewFactureData(id));
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (facture != null) {
      setData(facture.autreFrais);
    }
  }, [facture, attachedTranches]);

  const addPaiment = (values) => {
    values.etudiant_id = id;
    values.autre_frais_id = selectedTranche.id;
    dispatch(addPaimentData(values, handlePaimentClose));
  };

  const attachAutreTrancheSuccess = () => {
    dispatch(viewInscriptionAutreFraisData(id));
    dispatch(viewFactureData(id));
    handleClose();
  };
  const handleSubmit = () => {
    dispatch(attachAutreFraisData(autreTranche, id, attachAutreTrancheSuccess));
  };
  const handleDelete = (autreTrancheId) => {
    dispatch(detachAutreFraisData(autreTrancheId, id, attachAutreTrancheSuccess));
  };

  const showConfirm = (autreTrancheId) => {
    confirm({
      title: 'Voulez-vous detacher le frais ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(autreTrancheId);
      },
      onCancel() {},
    });
  };

  return (
    <Row gutter={25}>
      <Col lg={24} md={24} xs={24}>
        <Button
          key="1"
          type="danger"
          size="default"
          style={{ borderRadius: '17px', marginBottom: '20px' }}
          onClick={handleOpen}
        >
          Attacher Autre Frais
        </Button>
      </Col>
      <Modal
        className="edulink-event-form"
        footer={null}
        closable
        type="primary"
        title={'Attacher autre frais'}
        open={open}
        onCancel={handleClose}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Club :</span>
              <Form.Item>
                <Select
                  onChange={(e) => setAutreTranche(e)}
                  options={tranches.map((item) => {
                    return { value: item.id, label: item.nom };
                  })}
                  placeholder="Choisir autre frais "
                />
              </Form.Item>
            </div>
            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </div>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Modal open={isPaimentOpen} onCancel={handlePaimentClose} footer={null} title={`Frais : ${selectedTranche?.nom}`}>
        <Row gutter={25}>
          <Form form={paimentForm} onFinish={addPaiment}>
            <Form.Item name="payer">
              <Input placeholder="Saisir le montant a payer" style={{ marginLeft: '10px', color: 'black' }} />
            </Form.Item>

            <div className="add-event-footer text-right" style={{ width: '480px' }}>
              <Button htmlType="submit" className="btn-save" type="primary">
                payer
              </Button>
            </div>
          </Form>
        </Row>
      </Modal>

      {data
        ?.sort((a, b) => a.id > b.id)
        .map((item) => {
          return (
            <>
              <Col xxl={8} lg={12} sm={12} xs={24} key={item.id}>
                <PricingCard style={{ marginBottom: 30 }} onClick={() => handlePaimentOpen(item)}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge
                      className="pricing-badge"
                      type={item.paid ? (item.paid == item.prix ? 'success' : 'warning') : 'danger'}
                    >
                      Frais : {item.nom}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}>
                      <UilTrashAlt
                        size={17}
                        color={'black'}
                        onClick={(e) => {
                          e.stopPropagation();
                          showConfirm(item.id);
                        }}
                      />
                    </div>
                  </div>
                  <Heading className="price-amount" as="h3">
                    <sup className="currency"></sup>
                    {item.paid ? item.paid : '0'}/{item.prix} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            </>
          );
        })}
    </Row>
  );
}

export default Tranches;
