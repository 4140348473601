export function calculateTotalPaid(data) {
  let totalPaid = 0;

  // Calculate paid amount from clubRegistrations
  if (data.clubs) {
    data.clubs.forEach((event) => {
      if (event.paid) {
        totalPaid += event.paid;
      }
    });
  }

  // Calculate paid amount from eventRegistrations
  if (data.events) {
    data.eventRegistrations.forEach((event) => {
      if (event.paid) {
        totalPaid += event.paid;
      }
    });
  }

  // Calculate paid amount from installments
  if (data.modalites) {
    data.modalites.forEach((modalite) => {
      if (modalite.paid) {
        totalPaid += modalite.paid;
      }
    });
  }

  // Calculate paid amount from otherInstallments
  if (data.autresFrais) {
    data.autresFrais.forEach((autresFrais) => {
      if (autresFrais.paid) {
        totalPaid += autresFrais.paid;
      }
    });
  }

  return totalPaid;
}
