/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, DatePicker, Select, TimePicker, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../../redux/prof/classe/actionCreator';
import { addExerciceData } from '../../../redux/prof/exercice/actionCreator';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
import { viewMatiereData } from '../../../redux/prof/matiere/actionCreator';

function AddExerciceForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewMatiereData());
  }, []);
  const [form] = Form.useForm();
  const [niveau, setNiveau] = useState();
  const [niveaux, setNiveaux] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [matieresData, setMatieresData] = useState([]);

  const submitSuccess = () => {
    navigate(-1);
  };

  const { classes, matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereProfReducer.data,
      classes: state.ProfClasseReducer.arrayData,
    };
  });

  useEffect(() => {
    if (matieres.length > 0) {
      const data = matieres
        .filter((item, index, self) => index === self.findIndex((t) => t.nom === item.nom) && item.niveau == niveau)
        .map((item) => ({
          label: item.nom,
          value: item.nom,
        }));
      setMatieresData(data);
    }
  }, [niveau, matieres]);

  useEffect(() => {
    if (classes.length > 0) {
      const uniqueNiveauList = Array.from(new Set(classes.map((item) => item.niveau)))
        .sort((a, b) => a - b)
        .map((niveau) => ({
          value: niveau.toString(),
          label: niveau === 1 ? `${niveau}ère année` : `${niveau}ème année`,
        }));
      setNiveaux(uniqueNiveauList);
      setNiveau(uniqueNiveauList[0].value);
    }
  }, [classes]);

  useEffect(() => {
    const data = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = data.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleSubmit = (values) => {
    values.date = values.date.format('YYYY-MM-DD');
    values.dateL = values.dateL.format('YYYY-MM-DD');
    values.heurL = values.heurL.format('HH:mm');
    values.heur = values.heur.format('HH:mm');
    if (fileName) {
      const isPdf = /(\.pdf)$/i.test(fileName);
      isPdf ? (values.pdf = fileName) : (values.image = fileName);
    }

    dispatch(addExerciceData(values, submitSuccess));
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/Professeur/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Ajouter Un Exercice </div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item label="Niveau" rules={[{ required: true, message: 'Niveau est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            onChange={(e) => setNiveau(e)}
                            options={niveaux}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="classe_id"
                          label="Classe"
                          rules={[{ required: true, message: 'Classe est obligatoire!' }]}
                        >
                          <Select style={{ width: '100%' }} placeholder="Classe" options={classesSelect} />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="matiere"
                          label="Matière"
                          rules={[{ required: true, message: 'Matière est obligatoire !' }]}
                        >
                          <Select style={{ width: '100%' }} placeholder="Niveau" options={matieresData} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="date"
                          label="Date Debut"
                          rules={[{ required: true, message: 'Date debut est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="dateL"
                          label="Date Limite"
                          rules={[{ required: true, message: 'Date Limite est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Debut"
                          name="heur"
                          rules={[{ required: true, message: 'Heur limite debut est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Fin"
                          name="heurL"
                          rules={[{ required: true, message: 'Heur limite fin est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Ajouter un fichier">
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default AddExerciceForm;
