import { Row, Col, Modal, Form, Input } from 'antd';
import { BasicFormWrapper, Main } from '../styled';
import { PricingCard, Badge } from './Style';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  addAutreFraisData,
  updateAutreFraisData,
  viewAutreFraisData,
  deleteAutreFraisData,
} from '../../redux/autreFrais/actionCreator';
import { ReunionFormStyleWrap } from '../absence_eleve/Style';
import { ExclamationCircleFilled } from '@ant-design/icons';

function AutreTranches() {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [openAddModel, setOpenAddModel] = useState(false);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const { tranches } = useSelector((state) => {
    return {
      tranches: state.AutreFraisReducer.data,
    };
  });
  const showAddModal = () => {
    setOpenAddModel(true);
  };

  const showUpdateModal = (values) => {
    setSelectedTranche(values);
    form.setFieldsValue(values);
    setOpenAddModel(true);
  };

  const handleAddCancel = () => {
    setOpenAddModel(false);
  };

  const addSuccess = () => {
    handleAddCancel();
  };

  const handleDelete = (id) => {
    dispatch(deleteAutreFraisData(id));
  };

  const showConfirm = (data) => {
    confirm({
      title: 'Voulez-vous supprimer la tranche ?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(data);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    dispatch(viewAutreFraisData());
  }, []);

  const handleSubmit = (values) => {
    if (selectedTranche) {
      dispatch(updateAutreFraisData({ ...selectedTranche, ...values }, addSuccess));
    } else {
      dispatch(addAutreFraisData(values, addSuccess));
    }
  };

  return (
    <>
      <Modal
        className="edulink-event-form"
        footer={null}
        type="primary"
        title={'Ajouter un frais'}
        open={openAddModel}
        onCancel={handleAddCancel}
      >
        <BasicFormWrapper>
          <ReunionFormStyleWrap>
            <Form form={form} name="addReunion" onFinish={handleSubmit}>
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Titre</span>
                <Form.Item name="nom" rules={[{ required: true, message: 'Titre du frais est obligatoire !' }]}>
                  <Input
                    placeholder="Saisir le nom du frais"
                    disabled={selectedTranche && selectedTranche.edit == 0}
                  />
                </Form.Item>
              </div>
              <br />
              <div className="edulink-event-form-input">
                <span className="edulink-event-form-label">Montant</span>
                <Form.Item name="prix" rules={[{ required: true, message: 'Montant du frais est obligatoire !' }]}>
                  <Input placeholder="Saisir le montant du frais" type={'number'} min={0} />
                </Form.Item>
              </div>
              <br />
              <div className="add-event-footer text-right">
                <Button htmlType="submit" className="btn-save" type="primary">
                  Enregistrer
                </Button>
              </div>
            </Form>
          </ReunionFormStyleWrap>
        </BasicFormWrapper>
      </Modal>
      <Main>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}>
          <Button key="1" type="danger" size="default" style={{ borderRadius: '17px' }} onClick={showAddModal}>
            Ajouter un frais
          </Button>
        </div>
        <Row gutter={25}>
          {tranches &&
            tranches
              .sort((a, b) => a.edit - b.edit)
              .map((item) => (
                <Col key={item.id} xxl={8} lg={8} sm={12} xs={24}>
                  <PricingCard style={{ marginBottom: 30 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Badge className="pricing-badge" type="primary">
                        {item.nom}
                      </Badge>
                      <div style={{ display: 'flex', columnGap: '5px' }}>
                        <UilEdit size={17} color={'black'} onClick={() => showUpdateModal(item)} />
                        {item.edit == 1 && <UilTrash size={17} color={'black'} onClick={() => showConfirm(item.id)} />}
                      </div>
                    </div>
                    <Heading className="price-amount" as="h3">
                      <sup className="currency"></sup>
                      {item.prix} DT <sub className="pricing-validity">par tranche</sub>
                    </Heading>
                  </PricingCard>
                </Col>
              ))}
        </Row>
      </Main>
    </>
  );
}

export default AutreTranches;
