import { DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { ReunionFormStyleWrap } from './Style';
function Update({ open, handleUpdateCancel, action, data }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  const handleSubmit = (values) => {
    action(values);
  };

  return (
    <Modal
      className="edulink-event-form"
      footer={null}
      closable
      type="primary"
      title={'Modifier matiere'}
      open={open}
      onCancel={handleUpdateCancel}
    >
      <BasicFormWrapper>
        <ReunionFormStyleWrap>
          <Form form={form} name="addReunion" onFinish={handleSubmit}>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Nom :</span>
              <Form.Item name="nom" rules={[{ required: true, message: 'Le code est obligatoire !' }]}>
                <Input placeholder="Choisir un nom" />
              </Form.Item>
            </div>
            <div className="edulink-event-form-input">
              <span className="edulink-event-form-label">Coefficient :</span>
              <Form.Item name="coeff" rules={[{ required: true, message: 'Le coefficient est obligatoire !' }]}>
                <Input type="number" placeholder="Saisir un coefficient" />
              </Form.Item>
            </div>
            <div className="add-event-footer text-right">
              <Button htmlType="submit" className="btn-save" type="primary">
                Enregistrer
              </Button>
            </div>
          </Form>
        </ReunionFormStyleWrap>
      </BasicFormWrapper>
    </Modal>
  );
}

export default Update;
