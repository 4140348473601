import { calculateTotalPaid } from './calculateTotalPaid';

// utils/printInvoice.js
export const printInvoice = (factureToPrintData) => {
  const total = calculateTotalPaid(factureToPrintData);
  const printWindow = window.open('', '_blank');

  const invoiceHtml = `
      <html>
        <head>
          <title>Facture</title>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"
            rel="stylesheet"
          />
          <style>
            body {
                font-family: "Roboto", sans-serif;
                background-color: #f4f4f4;
                margin: 0;
                padding: 0;
            }
            .invoice-container {
                padding: 20mm;
            }
            .header {
                text-align: center;
                padding-bottom: 20px;
            }
            .header h1 {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
            }
            .header p {
                margin: 5px 0;
                font-size: 8px;
                color: #555;
            }
            .section-title {
                font-size: 12px;
                font-weight: 500;
                margin: 20px 0 10px;
            }
            .table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 10px;
            }
            .table th, .table td {
                padding: 10px;
                border: 1px solid #ddd;
                text-align: left;
                font-size: 8px;
            }
            .table th {
                font-weight: 500;
            }
            .total {
                text-align: right;
                margin-top: 20px;
            }
            .total h3 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
            }
            .footer {
                text-align: center;
                padding: 30px 0;
                margin-top: 30px;
                font-size: 1rem;
                border-top: 1px solid #ddd;
            }
          </style>
        </head>
        <body>
          <div class="invoice-container">
            <div class="header">
              <h1>Facture</h1>
            </div>

            <!-- Clubs Table -->
            ${
              factureToPrintData.clubs?.length > 0
                ? `
              <div class="section-title">Paiements des clubs</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.clubs
                    .map(
                      (club) => `
                      <tr>
                        <td>${club.titre}</td>
                        <td>${club.frais} TND</td>
                        <td>${club.paid} TND</td>
                      </tr>`,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }

            <!-- Events Table -->
            ${
              factureToPrintData.events?.length > 0
                ? `
              <div class="section-title">Paiements d'événements</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.events
                    .map(
                      (event) => `
                      <tr>
                        <td>${event.titre}</td>
                        <td>${event.frais} TND</td>
                        <td>${event.paid} TND</td>
                      </tr>`,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }

            <!-- Modalites Table -->
            ${
              factureToPrintData.modalites?.length > 0
                ? `
              <div class="section-title">Frais</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.modalites
                    .map(
                      (mod) => `
                      <tr>
                        <td>${mod.mois}</td>
                        <td>${mod.prix} TND</td>
                        <td>${mod.paid} TND</td>
                      </tr>`,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }

            <!-- Autre Frais Table -->
            ${
              factureToPrintData.autreFrais?.length > 0
                ? `
              <div class="section-title">Autre Frais</div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Montant</th>
                    <th>Payé</th>
                  </tr>
                </thead>
                <tbody>
                  ${factureToPrintData.autreFrais
                    .map(
                      (frais) => `
                      <tr>
                        <td>${frais.nom}</td>
                        <td>${frais.prix} TND</td>
                        <td>${frais.paid} TND</td>
                      </tr>`,
                    )
                    .join('')}
                </tbody>
              </table>
            `
                : ''
            }

            <!-- Total -->
            <div class="total">
              <h3>Total: ${total} TND</h3>
            </div>
          </div>
        </body>
      </html>
    `;

  printWindow.document.write(invoiceHtml);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.addEventListener('afterprint', () => {
    printWindow.close();
  });
};
