import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton, Modal, notification } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PricingCard, ListGroup, Badge } from './Style';
import Heading from '../../components/heading/heading';
import { List } from '../../components/pricing/pricing';
import { Button } from '../../components/buttons/buttons';
import { NavLink, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewSingleInscriptionData } from '../../redux/inscription/actionCreator';
import { viewFraisData } from '../../redux/frais/actionCreator';
import { addPaimentData, viewPaimentData } from '../../redux/paiment/actionCreator';
import Tranches from './overview/Tranches';
import Clubs from './overview/Club';
import Events from './overview/Event';
import { SettingWrapper } from './overview/Style';
import AutreTranches from './overview/AutreTranches';

const UserBio = lazy(() => import('./overview/UserBio'));
function MyProfile() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { inscription, frais, paiments } = useSelector((state) => {
    return {
      paiments: state.PaimentReducer.data,
      inscription: state.InscriptionReducer.singleData,
      frais: state.FraisReducer.data,
    };
  });
  const [singlePayment, setSinglePayment] = useState([]);
  useEffect(() => {
    if (paiments && Object.keys(paiments).includes(id)) {
      setSinglePayment(paiments[`${id}`]);
    }
  }, [paiments]);
  useEffect(() => {
    dispatch(viewSingleInscriptionData(id));
    dispatch(viewFraisData());
    dispatch(viewPaimentData());
  }, [id]);

  const [openConfirmPayment, setOpenConfirmPayment] = useState(false);

  const handleCancelConfirmPayment = () => {
    setOpenConfirmPayment(false);
  };

  const handleAdd = (data) => {
    dispatch(addPaimentData({ tranche: data.tranche, id: id }, () => {}));
  };

  return (
    <>
      <Modal
        open={openConfirmPayment}
        onCancel={handleCancelConfirmPayment}
        closable={false}
        title={null}
        footer={null}
      >
        <div className="confirm-delete-modal">
          <h4>êtes-vous sûr d'ajouter un payment</h4>
          <Row gutter={25}>
            <Col xxl={14} lg={14} md={14} xs={14}></Col>
            <Col xxl={4} lg={4} md={4} xs={4}>
              <Button key="back" onClick={handleCancelConfirmPayment}>
                Cancel
              </Button>
            </Col>
            <Col xxl={4} lg={4} md={4} xs={4}>
              <Button key="submit" type="success" onClick={handleCancelConfirmPayment}>
                Confirmer
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar active paragraph={{ rows: 3 }} />
                </Cards>
              }
            ></Suspense>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              <UserBio inscription={inscription} />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <div className="coverWrapper">
                  <nav className="profileTab-menu">
                    <ul>
                      <li>
                        <NavLink to={`./tranches/${id}`}>Frais</NavLink>
                      </li>
                      <li>
                        <NavLink to={`./autretranches/${id}`}>Autre Frais</NavLink>
                      </li>
                      <li>
                        <NavLink to={`./clubs/${id}`}>Clubs</NavLink>
                      </li>
                      <li>
                        <NavLink to={`./events/${id}`}>Evenements</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Suspense>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active paragraph={{ rows: 10 }} />
                  </Cards>
                }
              >
                <Outlet/>
              </Suspense>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
}

MyProfile.propTypes = {
  // match: propTypes.object,
};

export default MyProfile;
