import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import SimpleReactLightbox from 'simple-react-lightbox';
import store from './redux/store';
import Admin from './routes/admin';
import SuperAdmin from './routes/superadmin';
import Prof from './routes/prof';
import Auth from './routes/auth';
import Chat from './routes/chat';
import ChatParent from './routes/chatParent';
import ChatProf from './routes/chatProf';
import Parent from './routes/parent';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, role } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.token,
      role: state.auth.role,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <SimpleReactLightbox>
          <Router basename={process.env.PUBLIC_URL}>
            {!isLoggedIn ? (
              <Routes>
                <Route path="/*" element={<Auth />} />
              </Routes>
            ) : role == 'parents' ? (
              <Routes>
                <Route path="/parents/*" element={<ProtectedRoute path="/*" Component={Parent} />} />
                {/* <Route path="/chat/*" element={<ProtectedRoute path="/*" Component={ChatParent} />} /> */}
                <Route path="/*" element={<Navigate to="/parents/profile/timeline" />} />
              </Routes>
            ) : role == 'admin' ? (
              <Routes>
                <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                {/* <Route path="/chat/*" element={<ProtectedRoute path="/*" Component={Chat} />} /> */}
                <Route path="/*" element={<Navigate to="/admin" />} />
              </Routes>
            ) : role == 'professeur' ? (
              <Routes>
                <Route path="/professeur/*" element={<ProtectedRoute path="/*" Component={Prof} />} />
                {/* <Route path="/chat/*" element={<ProtectedRoute path="/*" Component={ChatProf} />} /> */}
                <Route path="/*" element={<Navigate to="/professeur" />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/superadmin/*" element={<ProtectedRoute path="/*" Component={SuperAdmin} />} />
                {/* <Route path="/chat/*" element={<ProtectedRoute path="/*" Component={Chat} />} /> */}
                <Route path="/*" element={<Navigate to="/superadmin" />} />
              </Routes>
            )}
          </Router>
        </SimpleReactLightbox>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
