/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, DatePicker, Select, notification, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../redux/classe/actionCreator';
import {
  addInscriptionData,
  updateInscriptionData,
  viewSingleInscriptionData,
} from '../../redux/inscription/actionCreator';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
function UpdateInscriptionForm() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewSingleInscriptionData(id));
  }, []);
  const { classes, inscription } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscription: state.InscriptionReducer.singleData,
    };
  });
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [form] = Form.useForm();

  const submitSuccess = () => {
    navigate(-1);
  };

  const submit = () => {
    const data = {
      nom,
      prenom,
      dateNaiss: typeof dateNaiss === 'string' ? dateNaiss : dateNaiss.format('YYYY-MM-DD'),
      prenomM,
      nomM,
      numM,
      prenomP,
      nomP,
      numP,
      email,
      niveau,
      reduction,
      reductionInscription,
      classe_id: classeId,
      image: fileName,
    };

    dispatch(updateInscriptionData(inscription.id, data, submitSuccess));
  };
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState([]);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [dateNaiss, setDateNaiss] = useState(null);
  const [prenomM, setPrenomM] = useState('');
  const [nomM, setNomM] = useState('');
  const [numM, setNumM] = useState('');
  const [prenomP, setPrenomP] = useState('');
  const [nomP, setNomP] = useState('');
  const [numP, setNumP] = useState('');
  const [email, setEmail] = useState('');
  const [classeId, setClasseId] = useState(null);
  const [reduction, setReduction] = useState(0);
  const [reductionInscription, setReductionInscription] = useState(0);
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (inscription) {
      console.log(inscription);

      const classe = inscription.classe;
      setNom(inscription.nom);
      setPrenom(inscription.prenom);
      setDateNaiss(moment(inscription.dateNaiss));
      setPrenomM(inscription.prenomM);
      setNomM(inscription.nomM);
      setNumM(inscription.numM);
      setPrenomP(inscription.prenomP);
      setNomP(inscription.nomP);
      setNumP(inscription.numP);
      setEmail(inscription.email);
      setNiveau(classe.niveau);
      setClasseId(classe.id);
      setFileName(inscription.image);
      setReduction(inscription.reduction);
      setReductionInscription(inscription.reductionFI);
      form.setFieldsValue({
        image: [
          {
            name: inscription.image,
            status: 'done',
            url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${inscription.image}`,
          },
        ],
      });
    }
  }, [inscription]);
  useEffect(() => {
    const data = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = data.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);
  const handleAnnuler = () => {
    navigate(-1);
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);

          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={submit}>
                    <div className="form-title">Mettre l'élève à jour</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Prénom Élève"
                          rules={[{ required: true, message: "Le prénom d'élève est obligatoire !" }]}
                        >
                          <Input
                            placeholder="Prénom élève"
                            value={prenom}
                            onChange={(e) => setPrenom(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Nom Élève"
                          rules={[{ required: true, message: "Le nom d'élève est obligatoire !" }]}
                        >
                          <Input placeholder="Nom élève" value={nom} onChange={(e) => setNom(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          label="Date De Naissance"
                          rules={[{ required: true, message: 'Date est obligatoire!' }]}
                        >
                          <DatePicker
                            placeholder="Date De Naissance"
                            value={dateNaiss}
                            onChange={(e) => setDateNaiss(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Prénom Parent"
                          rules={[{ required: true, message: 'Le prénom du parent est obligatoire !' }]}
                        >
                          <Input
                            placeholder="Prénom Parent"
                            value={prenomM}
                            onChange={(e) => setPrenomM(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Nom parent"
                          rules={[{ required: true, message: 'Le nom du parent est obligatoire !' }]}
                        >
                          <Input placeholder="Nom parent" value={nomM} onChange={(e) => setNomM(e.target.value)} />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Téléphone parent"
                          rules={[{ required: true, message: 'Téléphone du parent est obligatoire!' }]}
                        >
                          <Input
                            placeholder="Téléphone parent"
                            value={numM}
                            onChange={(e) => setNumM(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="E-mail Parent">
                          <Input placeholder="E-mail Parent" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Prénom Parent 2"
                          rules={[{ required: true, message: 'Le prénom du parent 2 est obligatoire !' }]}
                        >
                          <Input
                            placeholder="Prénom Parent 2"
                            value={prenomP}
                            onChange={(e) => setPrenomP(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Nom Parent 2"
                          rules={[{ required: true, message: 'Le nom du parent 2 est obligatoire !' }]}
                        >
                          <Input placeholder="Nom Parent 2" value={nomP} onChange={(e) => setNomP(e.target.value)} />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item
                          label="Téléphone Parent 2"
                          rules={[{ required: true, message: 'Téléphone du parent 2 est obligatoire!' }]}
                        >
                          <Input
                            placeholder="Téléphone Parent 2"
                            value={numP}
                            onChange={(e) => setNumP(e.target.value)}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item label="Niveau" rules={[{ required: true, message: 'Niveau est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            onChange={(e) => setNiveau(e)}
                            value={niveau}
                            options={[
                              { value: '1', label: '1ère année' },
                              { value: '2', label: '2ème année' },
                              { value: '3', label: '3ème année' },
                              { value: '4', label: '4ème année' },
                              { value: '5', label: '5ème année' },
                              { value: '6', label: '6ème année' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Classe" rules={[{ required: true, message: 'Classe est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Classe"
                            options={classesSelect}
                            value={classeId}
                            onChange={(e) => setClasseId(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Reduction">
                          <Input
                            placeholder="Reduction"
                            value={reduction}
                            onChange={(e) => setReduction(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Reduction Frais Inscription">
                          <Input
                            placeholder="Reduction Frais Inscription"
                            value={reductionInscription}
                            onChange={(e) => setReductionInscription(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name={'image'}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          label="Photo d'élève"
                        >
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Modifier
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdateInscriptionForm;
