import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
//import MessageProf from './MessageProf';
//import ParentMessage from './ParentMessage';
import Notification from './Notification';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { logOut } from '../../../redux/authentication/actionCreator';
import Message from './Message';
import { t } from 'i18next';
const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { data } = useSelector((state) => {
    return {
      data: state.auth.data,
    };
  });

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const role = useSelector((state) => state.auth.role);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
            style={{
              height: '40px',
              width: '40px',
              borderRadius: '40px',
              objectFit: 'cover',
            }}
            onError={(event) => {
              event.target.src = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png';
            }}
          />
          <figcaption>
            <Heading as="h5">
              {role === 'admin' ? data.organisation : `${data && t(data.prenom)} ${data && t(data.nom)}`}
            </Heading>
            <p>{role}</p>
          </figcaption>
        </figure>

        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* {role == 'admin' ? (
        <Message />
      ) : role == 'professeur' ? (
        <MessageProf />
      ) : role == 'parents' ? (
        <ParentMessage />
      ) : null} */}
      <Notification />
      <div className="edulink-nav-actions__item edulink-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="edulink-nav-action-link" style={{ display: 'flex' }}>
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
              style={{
                height: '35px',
                width: '35px',
                borderRadius: '35px',
                objectFit: 'cover',
              }}
              onError={(event) => {
                event.target.src = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png';
              }}
            />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
